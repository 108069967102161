<template>
  <div class="payment-account">
    <x-loader
      v-if="isLoading"
    />

    <template v-else>
      <template v-if="paymentAccounts.length">
        <template v-if="isUserAdmin">
          <div class="payment-account__actions">
            <x-btn
              @click="openAddPaymentAccountModal"
            >
              Добавить счет
            </x-btn>
          </div>
        </template>

        <grid
          auto
          :actions="actions"
          :cells="paymentAccounts"
          :columns="paymentAccountColumns"
          class="payment-account__grid"
        />

        <x-paginator v-model="pagination" />
      </template>

      <x-placeholder
        v-else
        icon="table-empty"
        desc="Вы еще не добавили ни одного счета"
      >
        <template #bottom>
          <template v-if="isUserAdmin">
            <x-btn
              @click="openAddPaymentAccountModal"
            >
              Добавить счет
            </x-btn>
          </template>
        </template>
      </x-placeholder>
    </template>

    <add-payment-account-modal @success="fetchData" />
    <edit-payment-account-modal @success="fetchData" />

    <payment-doc-modal />

    <confirm-modal @decline="$modal.hide('confirm')">
      <template #text>
        Вы точно хотите удалить счет?
      </template>
    </confirm-modal>
  </div>
</template>

<script>
import Grid from '@/components/Interface/Grid.vue'
import ConfirmModal from '@/components/Common/ConfirmModal.vue'
import PaymentDocModal from '@/components/Settings/Finances/PaymentAccounts/PaymentDocModal.vue'
import AddPaymentAccountModal from '@/components/Settings/Finances/PaymentAccounts/AddPaymentAccountModal.vue'
import { paymentAccountColumns } from '@/constants/settings'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import EditPaymentAccountModal from '@/components/Settings/Finances/PaymentAccounts/EditPaymentAccountModal'

const { mapActions, mapState } = createNamespacedHelpers('settings/finances')

export default {
  components: {
    EditPaymentAccountModal,
    Grid,
    ConfirmModal,
    PaymentDocModal,
    AddPaymentAccountModal
  },

  data () {
    return {
      paymentAccountColumns,
      pagination: {
        page: 1,
        limit: 10,
        total: null
      },
      isLoading: false,
      isDeleteProcessed: false
    }
  },

  watch: {
    pagination () {
      this.fetchData()
    }
  },

  computed: {
    ...mapState(['paymentAccounts']),
    ...mapGetters('profile', ['isUserAdmin']),

    actions () {
      const deleteAction = {
        // iconName: 'delete-2',
        label: 'Удалить',
        cb: this.onDeleteClick
      }
      const actions = [
        {
          // iconName: 'edit',
          label: 'Редактировать',
          cb: this.onEditPaymentAccountClick
        },
        {
          // iconName: 'list',
          label: 'Скачать платежный документ',
          cb: this.onDownloadPaymentDocClick
        }
      ]

      if (this.isUserAdmin) {
        actions.unshift(deleteAction)
      }

      return actions
    }
  },

  created () {
    this.fetchData()
  },

  methods: {
    ...mapActions([
      'getPaymentAccounts',
      'deletePaymentAccount',
      'downloadPaymentDoc'
    ]),

    async fetchData () {
      try {
        this.isLoading = true
        this.pagination.total = await this.getPaymentAccounts(this.pagination)
      } finally {
        this.isLoading = false
      }
    },

    async onDeletePaymentAccount (account) {
      try {
        this.isDeleteProcessed = true

        await this.deletePaymentAccount(account.id)

        this.fetchData()
      } finally {
        this.isDeleteProcessed = false
        this.$modal.hide('confirm')
      }
    },

    onDeleteClick (row) {
      this.$modal.show('confirm', { onConfirm: () => this.onDeletePaymentAccount(row) })
    },

    async onDownloadPaymentDoc ({ account, amount }) {
      try {
        await this.downloadPaymentDoc({
          amount,
          payment_account: account.id
        })
      } finally {
        this.$modal.hide('payment-doc-modal')
      }
    },

    onDownloadPaymentDocClick (account) {
      this.$modal.show('payment-doc-modal', { account, onSubmit: this.onDownloadPaymentDoc })
    },

    onEditPaymentAccountClick (account) {
      this.$modal.show('edit-payment-account-modal', { account })
    },

    openAddPaymentAccountModal () {
      this.$modal.show('add-payment-account-modal')
    }
  }
}
</script>

<style lang="stylus" scoped>
.payment-account
  &__actions
    display flex
    align-items center
    justify-content flex-end
    margin-bottom 20px

  &__grid
    margin-bottom 20px
</style>
