<template>
  <modal
    height="auto"
    :name="modalName"
  >
    <div class="modal-body">
      <close-button @click="$modal.hide(modalName)" />

      <validation-observer v-slot="{ handleSubmit }">
        <seller-form @submit="handleSubmit(onSubmit)">
          <x-form-item
            v-slot="{ validationData }"
            name="Сумма"
            label="Введите сумму"
            rules="required|isNumber|min_value:1"
            class="modal-body__field"
          >
            <x-input
              :value="amount"
              v-bind="validationData"
              placeholder="Сумма, руб"
              @input="onAmountInput"
            />
          </x-form-item>

          <x-btn
            type="submit"
            :loading="isLoading"
          >
            Оплатить картой
          </x-btn>
        </seller-form>
      </validation-observer>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import SellerForm from '@/components/Interface/Form.vue'
import { initCardPayment } from '@/api/default/modules/payment'

export default {
  name: 'CardPaymentModal',
  components: {
    CloseButton,
    SellerForm
  },

  data () {
    return {
      modalName: 'card-payment-modal',
      isLoading: false,
      amount: null
    }
  },

  methods: {
    async onSubmit () {
      try {
        this.isLoading = true

        const { data } = await initCardPayment({
          amount: this.amount
        })

        this.$metrics.hit('mmp_settings_finances_card_done')

        if (data.data) {
          window.location.href = data.data.payment_url
        }
      } finally {
        this.isLoading = false
      }
    },

    onAmountInput (value) {
      this.amount = value.replace(/,/g, '.')
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-body
  padding 32px

  &__field
    margin-bottom 20px
</style>
