<template>
  <modal
    height="auto"
    :name="modalName"
  >
    <div class="modal-body">
      <heading level="3">
        Введите реквизиты счета
      </heading>

      <x-loader v-if="isLoading" />

      <requisites-form
        v-else
        v-model="form"
        submit-button-title="Выставить счет"
        class="modal-body__form scrollbar_primary"
        @submit="onSubmit"
        @cancel="$modal.hide(modalName)"
      />

      <close-button @click="$modal.hide(modalName)" />
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import RequisitesForm from '@/components/Settings/Finances/PaymentAccounts/RequisitesForm.vue'
import Heading from '@/components/Common/Heading.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('settings/finances')

export default {
  components: {
    RequisitesForm,
    CloseButton,
    Heading
  },

  data () {
    return {
      modalName: 'add-payment-account-modal',
      form: {
        client: null,
        organization_name: null,
        legal_address: null,
        inn: null,
        kpp: null,
        account_number: null,
        correspondent_account: null,
        bik: null,
        phone: null,
        fax: null,
        email: null
      },
      isLoading: false
    }
  },

  methods: {
    ...mapActions(['createPaymentAccount']),

    async onSubmit () {
      try {
        this.isLoading = true

        await this.createPaymentAccount({
          ...this.form,
          client: this.form.client.id
        })

        this.$emit('success')
        this.$modal.hide(this.modalName)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-body
    padding 32px

    &__form
      display block
      max-height 500px
      overflow-y scroll
      padding-right 10px
</style>
