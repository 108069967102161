<template>
  <div class="finances">
    <x-tabs
      size="l"
      :tabs="financesTabs"
      :selected="selectedTabIdx"
      class="finances__tabs"
      @change="setSelectedTab"
    />

    <transition name="tabs-fade">
      <component
        :is="currentTabComponent"
      />
    </transition>
  </div>
</template>

<script>
import * as Tabs from '@/components/Settings/Finances/Tabs'
import { financesTabs } from '@/constants/settings'
import '@/styles/transitions.styl'

export default {
  components: {
    ...Tabs
  },
  props: {
    tab: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      financesTabs,
      selectedTabIdx: 0
    }
  },

  watch: {
    $route: {
      handler () {
        if (this.tab) {
          this.selectedTabIdx = financesTabs.findIndex(item => item.id === this.tab)
          this.$router.push('/settings/finances')
        }
      },
      immediate: true
    }
  },

  computed: {
    currentTabComponent () {
      return financesTabs[this.selectedTabIdx].id
    }
  },

  methods: {
    setSelectedTab (tabIdx) {
      this.selectedTabIdx = tabIdx
    }
  }
}
</script>

<style lang="stylus" scoped>
  .finances
    &__tabs
      margin-bottom 20px
</style>
