var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history"},[_c('filters',{staticClass:"history__filters",attrs:{"transactions-type":_vm.transactionsType,"transaction-status":_vm.transactionsStatus},on:{"change:type":function (value) { return _vm.onFilterChange('type', value); },"change:status":function (value) { return _vm.onFilterChange('status', value); },"reset":_vm.onFiltersReset}}),(_vm.isLoading)?_c('x-loader'):_vm._e(),_c('transition',{attrs:{"name":"tabs-fade"}},[(!_vm.isLoading)?_c('div',[_c('grid',{staticClass:"history__grid",attrs:{"auto":"","cells":_vm.transactions,"columns":_vm.transactionHistoryColumns},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var type = ref.type;
return [(_vm.transactionTypes[type])?[_vm._v(" "+_vm._s(_vm.transactionTypes[type])+" ")]:_vm._e()]}},{key:"created",fn:function(ref){
var created = ref.created;
return [_vm._v(" "+_vm._s(_vm.formatDate(created))+" ")]}},{key:"modified",fn:function(ref){
var modified = ref.modified;
return [_vm._v(" "+_vm._s(_vm.formatDate(modified))+" ")]}},{key:"status",fn:function(ref){
var status = ref.status;
return [(_vm.transactionStatuses[status])?[_vm._v(" "+_vm._s(_vm.transactionStatuses[status])+" ")]:_vm._e()]}},{key:"data",fn:function(ref){
var data = ref.data;
return [(data.info)?[_vm._v(" "+_vm._s(data.info)+" ")]:_vm._e()]}},{key:"amount",fn:function(ref){
var amount = ref.amount;
return [(amount)?[_vm._v(" "+_vm._s(_vm.formatPrice(amount))+" руб ")]:_vm._e()]}}],null,false,2105267765)}),_c('x-paginator',{model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }