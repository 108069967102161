<template>
  <modal
    height="auto"
    :name="modalName"
    @before-open="onBeforeOpen"
  >
    <div class="modal-body">
      <heading level="3">
        Введите реквизиты счета
      </heading>

      <x-loader v-if="isLoading" />

      <requisites-form
        v-else
        v-model="form"
        class="modal-body__form scrollbar_primary"
        submit-button-title="Сохранить изменения"
        @submit="onSubmit"
        @cancel="$modal.hide(modalName)"
      />

      <close-button @click="$modal.hide(modalName)" />
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import RequisitesForm from '@/components/Settings/Finances/PaymentAccounts/RequisitesForm.vue'
import Heading from '@/components/Common/Heading.vue'
import { updatePaymentAccount } from '@/api/default/modules/settings/finances'

export default {
  name: 'EditPaymentAccountModal',
  components: {
    RequisitesForm,
    CloseButton,
    Heading
  },

  data () {
    return {
      modalName: 'edit-payment-account-modal',
      form: {
        client: null,
        organization_name: null,
        legal_address: null,
        inn: null,
        kpp: null,
        account_number: null,
        correspondent_account: null,
        bik: null,
        phone: null,
        fax: null,
        email: null
      },
      isLoading: false
    }
  },

  methods: {
    onBeforeOpen (e) {
      const { account } = e.params

      this.account = account

      Object.keys(this.form).forEach(key => {
        if (key in account) {
          if (key === 'client') {
            this.form.client = {
              id: account.client,
              name: account.client_name
            }
          } else {
            this.form[key] = account[key]
          }
        }
      })
    },

    async onSubmit () {
      try {
        this.isLoading = true

        await updatePaymentAccount(this.account.id, {
          ...this.form,
          client: this.form.client.id
        })

        this.$emit('success')
        this.$modal.hide(this.modalName)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-body
  padding 32px

  &__form
    display block
    max-height 500px
    overflow-y scroll
    padding-right 10px
</style>
