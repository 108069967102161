<template>
  <x-dialog
    title="Вы собираетесь отписаться"
    cancel-text="Нет"
    submit-text="Да"
    :loading="loading"
    @submit="submit"
  >
    <template #description>
      По окончанию срока действия текущей подписки вы потеряете доступ к личному кабинету.
      <span class="gray-darkest-2--text font-weight-semibold">
        Отменить подписку?
      </span>
    </template>
  </x-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState: mapProfileState } = createNamespacedHelpers('profile')
const { mapActions: mapSettingsFinancesActions } = createNamespacedHelpers('settings/finances')
const { mapActions: mapProfileActions, mapGetters: profileGetters } = createNamespacedHelpers('profile')

export default {
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapProfileState(['user']),
    ...profileGetters(['currentTariff'])
  },
  methods: {
    ...mapSettingsFinancesActions(['changeTariff']),
    ...mapProfileActions(['getProfile']),
    async submit () {
      this.loading = true
      try {
        // fucking shame, change to 'free' tariff
        await this.changeTariff({
          tariff_id: 2,
          term: 0
        })
        await this.getProfile()
        const expiresDate = new Date(this.currentTariff?.expires_at).toLocaleDateString()
        this.$dialog.showSuccess(() => ({
          title: 'Вы отключили автоподписку',
          description: `Срок ее действия закончится ${expiresDate}`
        }))
      } catch (e) {
        this.$dialog.showError(() => ({
          error: e,
          noSubmit: true
        }))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
