<template>
  <div class="row">
    <div
      v-if="isLoading"
      class="col-12"
    >
      <x-loader />
    </div>

    <template v-else>
      <div class="col-xl-5">
        <x-form-item
          name="Баланс"
          label="Баланс"
          class="mb-3"
        >
          <x-input
            readonly
            :value="formattedBalance"
          />
        </x-form-item>
        <x-form-item
          name="Текущий тариф"
          label="Текущий тариф"
          class="mb-3"
        >
          <x-input
            readonly
            :value="userTariffName || '-'"
          />
        </x-form-item>
        <div
          v-if="nowTemporaryTariff"
          class="mb-4 warning--text"
        >
          Нам не удалось списать стоимость тарифа с вашей карты. Пополните, пожалуйста, баланс карты, или привяжите другую карту, чтобы сохранить подписку и не потерять свои данные.
        </div>
        <div
          v-else-if="tariffChanged"
          class="mb-4 warning--text"
        >
          Вы переключились на тариф {{ futureTariff.name }}.
          Изменение произойдет после окончания действующей подписки {{ subscriptionExpiresDate }}
        </div>
        <x-form-item
          name="Оплачен до"
          label="Оплачен до"
          class="mb-3"
        >
          <x-input
            readonly
            :value="formattedExpirationDate"
          />
        </x-form-item>
        <!--        <x-link primary>-->
        <!--          Обещанный платёж-->
        <!--        </x-link>-->
      </div>
      <div class="col-12">
        <x-btn
          metrics="mmp_settings_finances_card"
          @click="openCardPaymentModal"
        >
          Оплатить картой
        </x-btn>
        <x-btn
          class="finances-main__button-card"
          metrics="mmp_settings_finances_check"
          @click="openTariffPaymentModal"
        >
          Выставить счет
        </x-btn>
        <x-btn
          v-if="showCancelAutosubscription"
          color="gray"
          @click="showCancelAutosubscriptionDialog"
        >
          Отменить автоподписку
        </x-btn>
      </div>
      <card-payment-modal />
      <tariff-payment-account-modal @success="openInvoiceDoneModal" />
    </template>
  </div>
</template>

<script>
import formatDate from '@/utils/date-formatter'
import { createNamespacedHelpers } from 'vuex'
import CardPaymentModal from '@/components/Settings/Finances/Main/CardPaymentModal'
import TariffPaymentAccountModal from '@/components/Tariffs/TariffPaymentAccountModal'
import TariffInvoiceDoneModal from '@/components/Tariffs/TariffInvoiceDoneModal'
import CancelAutosubscriptionDialog from './CancelAutosubscriptionDialog.vue'
import NoMorePay from '@/components/Tariffs/NoMorePay'

const { mapActions } = createNamespacedHelpers('settings/finances')
const { mapState: mapProfileState, mapActions: mapProfileActions, mapGetters: profileGetters } = createNamespacedHelpers('profile')

export default {
  components: { TariffPaymentAccountModal, CardPaymentModal },
  data () {
    return {
      financesInfo: {
        available_balance: '',
        tariff: {},
        tariff_expires_at: ''
      },
      isLoading: false
    }
  },

  computed: {
    ...mapProfileState(['user']),
    ...profileGetters(['currentTariff', 'futureTariff']),

    nowTemporaryTariff () {
      return this.currentTariff?.tariff?.key === 'temporary'
    },

    tariffChanged () {
      return this.futureTariff?.id !== this.currentTariff?.tariff?.id
    },

    subscriptionExpiresDate () {
      return new Date(this.currentTariff?.expires_at).toLocaleDateString()
    },

    showCancelAutosubscription () {
      return this.currentTariff?.is_recurrent && this.futureTariff?.key !== 'free'
    },

    formattedBalance () {
      const { available_balance: balance } = this.financesInfo
      if (!balance && typeof balance !== 'number') return ''
      return `${balance} руб`
    },

    formattedExpirationDate () {
      return formatDate(this.currentTariff?.expires_at)
    },

    userTariffName () {
      return this.currentTariff?.tariff?.name
    }
  },

  created () {
    this.fetchData()
  },

  methods: {
    ...mapActions(['getFinanceInfo']),
    ...mapProfileActions(['getProfile']),

    async fetchData () {
      try {
        this.isLoading = true
        await this.getProfile()
        const { tariffInfo, balanceInfo } = await this.getFinanceInfo()

        this.financesInfo = {
          ...tariffInfo,
          ...balanceInfo
        }
      } finally {
        this.isLoading = false
      }
    },

    openCardPaymentModal () {
      // this.$modal.show('card-payment-modal')
      this.$dialog.show(NoMorePay)
    },

    openTariffPaymentModal () {
      // this.$modal.show('tariff-payment-account-modal', { amount: 0 })
      this.$dialog.show(NoMorePay)
    },

    openInvoiceDoneModal () {
      this.$metrics.hit('mmp_settings_finances_check_done')
      // this.$dialog.show(TariffInvoiceDoneModal)
      this.$dialog.show(NoMorePay)
    },

    showCancelAutosubscriptionDialog () {
      this.$dialog.show(CancelAutosubscriptionDialog)
    }
  }
}
</script>
