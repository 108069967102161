<template>
  <div class="history">
    <filters
      :transactions-type="transactionsType"
      :transaction-status="transactionsStatus"
      class="history__filters"
      @change:type="value => onFilterChange('type', value)"
      @change:status="value => onFilterChange('status', value)"
      @reset="onFiltersReset"
    />

    <x-loader
      v-if="isLoading"
    />

    <transition name="tabs-fade">
      <div v-if="!isLoading">
        <grid
          auto
          :cells="transactions"
          :columns="transactionHistoryColumns"
          class="history__grid"
        >
          <template #type="{ type }">
            <template v-if="transactionTypes[type]">
              {{ transactionTypes[type] }}
            </template>
          </template>

          <template #created="{ created }">
            {{ formatDate(created) }}
          </template>

          <template #modified="{ modified }">
            {{ formatDate(modified) }}
          </template>

          <template #status="{ status }">
            <template v-if="transactionStatuses[status]">
              {{ transactionStatuses[status] }}
            </template>
          </template>

          <template #data="{ data }">
            <template v-if="data.info">
              {{ data.info }}
            </template>
          </template>

          <template #amount="{ amount }">
            <template v-if="amount">
              {{ formatPrice(amount) }} руб
            </template>
          </template>
        </grid>

        <x-paginator v-model="pagination" />
      </div>
    </transition>
  </div>
</template>

<script>
import Filters from '@/components/Settings/Finances/History/Filters.vue'
import Grid from '@/components/Interface/Grid.vue'
import formatDate from '@/utils/date-formatter'
import formatPrice from '@/utils/price-formatter'
import { transactionHistoryColumns } from '@/constants/settings'
import { createNamespacedHelpers } from 'vuex'
import '@/styles/transitions.styl'

const { mapActions, mapState } = createNamespacedHelpers('settings/finances')

const filterModels = {
  type: 'transactionsType',
  status: 'transactionsStatus'
}

const defaultFilterOption = {
  id: null,
  label: 'Все'
}

export default {
  components: {
    Filters,
    Grid
  },

  data () {
    return {
      transactionHistoryColumns,
      transactionsType: defaultFilterOption,
      transactionsStatus: defaultFilterOption,
      filters: {
        type: null,
        status: null
      },
      pagination: {
        page: 1,
        limit: 10,
        total: null
      },
      isLoading: false
    }
  },

  watch: {
    pagination () {
      this.fetchData()
    },

    filters () {
      this.fetchData()
    }
  },

  computed: {
    ...mapState([
      'transactions',
      'transactionTypes',
      'transactionStatuses'
    ])
  },

  created () {
    Promise.all([
      this.getTransactionsTypes(),
      this.getTransactionsStatuses(),
      this.fetchData()
    ])
  },

  methods: {
    ...mapActions([
      'getUserTransactions',
      'getTransactionsTypes',
      'getTransactionsStatuses'
    ]),

    formatDate,
    formatPrice,

    async fetchData () {
      try {
        this.isLoading = true

        const payload = {
          ...this.filters,
          ...this.pagination
        }

        this.pagination.total = await this.getUserTransactions(payload)
      } finally {
        this.isLoading = false
      }
    },

    onFilterChange (filter, value) {
      this.setFilterValue(filter, value)
      this.filters = {
        ...this.filters,
        [filter]: value.id
      }
    },

    setFilterValue (filter, value) {
      const filterModel = filterModels[filter] || filter
      this[filterModel] = value
    },

    onFiltersReset () {
      Object.keys(this.filters).forEach(key => {
        this.filters = {
          ...this.filters,
          [key]: null
        }
        this.setFilterValue(key, defaultFilterOption)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .history
    &__filters
      margin-bottom 20px

    &__grid
      margin-bottom 20px
</style>
