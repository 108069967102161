<template>
  <modal
    height="auto"
    :name="modalName"
    @before-open="onBeforeOpen"
  >
    <div class="modal-body">
      <close-button @click="$modal.hide(modalName)" />

      <validation-observer v-slot="{ handleSubmit }">
        <seller-form @submit="handleSubmit(onSubmit)">
          <x-form-item
            v-slot="{ validationData }"
            name="Сумма"
            label="Введите сумму"
            rules="required"
            class="modal-body__field"
          >
            <x-input
              v-model="amount"
              v-bind="validationData"
              placeholder="Сумма, руб"
            />
          </x-form-item>

          <x-btn
            type="submit"
            :loading="isLoading"
          >
            Сгенерировать платежный документ
          </x-btn>
        </seller-form>
      </validation-observer>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import SellerForm from '@/components/Interface/Form.vue'

export default {
  components: {
    CloseButton,
    SellerForm
  },

  data () {
    return {
      modalName: 'payment-doc-modal',
      onSubmitCb: null,
      isLoading: false,
      amount: null
    }
  },

  methods: {
    onBeforeOpen (e) {
      const { account, onSubmit } = e.params

      this.account = account
      this.onSubmitCb = onSubmit
    },

    async onSubmit () {
      try {
        this.isLoading = true

        await this.onSubmitCb({
          account: this.account,
          amount: this.amount
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-body
    padding 32px

    &__field
      margin-bottom 20px
</style>
