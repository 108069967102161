<template>
  <ul class="filters">
    <li class="filters__item">
      <x-form-item
        name="Тип транзакций"
        label="Тип транзакций"
      >
        <x-select
          track-by="id"
          label="label"
          :value="transactionsType"
          :options="getFilterOptions(transactionTypes)"
          :limit="1"
          :allow-empty="false"
          @select="type => $emit('change:type', type)"
        />
      </x-form-item>
    </li>

    <li class="filters__item">
      <x-form-item
        name="Статус транзакций"
        label="Статус транзакций"
      >
        <x-select
          track-by="id"
          label="label"
          :value="transactionStatus"
          :options="getFilterOptions(transactionStatuses)"
          :limit="1"
          :allow-empty="false"
          @select="status => $emit('change:status', status)"
        />
      </x-form-item>
    </li>

    <li class="filters__item filters__item_content_reset">
      <reset-button
        block
        @click="$emit('reset')"
      >
        Очистить фильтры
      </reset-button>
    </li>
  </ul>
</template>

<script>
import ResetButton from '@/components/Interface/ResetButton.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('settings/finances')

const defaultFilterOption = {
  id: null,
  label: 'Все'
}

export default {
  components: {
    ResetButton
  },

  props: {
    transactionsType: {
      type: Object,
      default: () => defaultFilterOption
    },
    transactionStatus: {
      type: Object,
      default: () => defaultFilterOption
    }
  },

  computed: {
    ...mapState([
      'transactionTypes',
      'transactionStatuses'
    ])
  },

  methods: {
    getFilterOptions (filter) {
      const options = Object.entries(filter).reduce((res, [key, value]) => {
        res.push({
          id: key,
          label: value
        })
        return res
      }, [])

      options.unshift(defaultFilterOption)

      return options
    }
  }
}
</script>

<style lang="stylus" scoped>
  .filters
    display flex
    flex-wrap wrap
    align-items center
    margin 0
    margin-left -10px
    padding 0
    list-style none

    &__item
      flex 0 0 245px
      margin-left 10px
      margin-bottom 10px

      &_content_reset
        padding-top 20px
</style>
